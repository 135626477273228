@mixin dialogue-footer-actions {
  .MuiDialogActions-root {	
		justify-content: space-evenly;
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
		.MuiButtonBase-root  {
			border-radius: 0 !important;
			padding: 6px 0px;
			border: 1px solid #4e5c73;
		}
		.MuiGrid-root.MuiGrid-item {
			width: 100%;
		}
		.MuiButton-contained.Mui-disabled {
			background-color: #394e73 !important;
			color: #7f96bb !important;
		}
		
	}
	.MuiDialogActions-spacing > :not(:first-child) {
		margin-left: 0px;
	}
}
