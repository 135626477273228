.dialogue-component {
	.MuiDialogTitle-root {
		padding: 8px;
		.close-button {
			position: absolute;
			right: 0;
			padding-top: 4px;
		}
	}
}
