@import './../../../theme.variables.scss';

.MuiBackdrop-root.backdrop-progress {
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;

  .MuiLinearProgress-root {
    width: 100%;
    // top: 64px;
    position: fixed;
  }

  .MuiLinearProgress-bar1Indeterminate,
  .MuiLinearProgress-bar2Indeterminate {
    width: 400px;
    animation: MuiLinearProgress-keyframes-indeterminate1 3s linear infinite;
  }

  .MuiLinearProgress-bar {
    transition: none;
  }

  .linear-progress {
    top: $header-height !important;
  }
}
