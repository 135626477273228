@import './../../../theme.variables.scss';
.errorMsg {
  padding: 20px;
  display: inline-flex;
}

.errorIcon {
  color: $color-aa-red;
  padding-right: 5px;
}
