$PrimaryColor: #2c3376;
$SecondaryColor: #2d5c06;
$textColor: #929090;
$textFadeOut: #cccccc;
$textWhite: #fff;
$textError: #b90a0a;
$header-height: 60px;
$linkedAccountColor: #2196f3;
$borderColorG: #71aa42;
$borderColorGray: #ddd;
$colorWhite: #fff;
$color-aa-red: #b90a0a;
$color-aaa-red: #b30303;
$color-aa-gray: #3a2f2f;
