@import '../../theme.variables.scss';
.header {
  height: 60px;
  .MuiToolbar-gutters {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  #user-profile {
    margin-right: 0px;
    .MuiButton-root {
      padding: 0 0 0 10px !important;
    }
  }
  .avatar-icon {
    background: $borderColorG !important;
    width: 35px !important;
    height: 35px !important;
    color: $textWhite !important;
    font-size: medium;
  }
  .avatar-linked-acct-icon {
    background: $linkedAccountColor !important;
    width: 35px !important;
    height: 35px !important;
    color: $textWhite !important;
    font-size: medium;
  }
  .toolTip {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .left-header {
    width: 20%;
  }
  .center-header {
    width: 60%;
  }
  .right-header {
    // width: 20%;
    text-align: right;
  }
}

.alertsRow {
  border-left: 6px solid $PrimaryColor !important;
  margin-bottom: 10px !important;
  background: #f9f9f9 !important;
}
.MuiTypography-body1 {
  font-size: 0.85rem !important;
}
.paperScroll {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.MuiDrawer-paper {
  overflow: hidden !important;
  width: 270px;
}
.drawer-header {
  background: $PrimaryColor;
  padding: 10px;
  color: $textWhite;
}
.menuList {
  width: 250px;
  overflow-y: auto;
  .MuiListItem-root {
    padding-top: 0px !important;
    padding-bottom: 4px !important;
  }
  .MuiListItemIcon-root {
    min-width: 30px !important;
    color: $SecondaryColor !important;
  }
}
.f-small {
  font-size: 0.75rem;
}
.sub-header {
  color: $PrimaryColor !important;
  .MuiTypography-body1 {
    font-weight: bold !important;
  }
}

.user-account-menu {
  &:before {
    content: '';
    border-bottom: 10px solid $colorWhite;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: -10px;
    right: 16px;
    z-index: 10;
  }
  padding: 0px !important;
  .MuiListItemIcon-root {
    min-width: 30px !important;
  }
  .MuiListItemText-root {
    margin-right: 10px;
  }
  .MuiListItem-root {
    border-bottom: 1px solid $borderColorGray;
    &:last-child {
      border-bottom: none;
    }
  }
  .selected {
    background: $SecondaryColor;
    color: $colorWhite;
    .MuiListItemIcon-root {
      color: $colorWhite;
    }
  }
  .not-selected {
    color: $SecondaryColor;
    background: $colorWhite;
  }
}
.transform {
  transform: translate3d(0px, 53px, 0px) !important;
  top: 10px !important;
}

.app-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modelStlye {
  width: 50%;
  height: 200px;
  background: $colorWhite;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #ccc;
  border-radius: 3%;
}
@media screen and (min-width: 600px) {
  .menuList .MuiListItem-root {
    padding-bottom: 10px !important;
  }
}

.alertText {
  font-family: 'Nunito', sans-serif !important;
  font-size: 0.85rem !important;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin: 0;
}

.blueLink {
  text-decoration: underline;
  color: #2c3376;
  cursor: pointer;
}

.proxyLinkText {
  padding-top: 12px;
}
