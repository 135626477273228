@import 'theme.variables.scss';
@import '../src/sass/mixins/components/dialogue-component.scss';

$btnBorder: 1px solid $borderColorG !important;

:root,
html,
body {
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
body {
  margin: 0;
}
@media screen and (max-width: 320px) {
  body,
  p,
  .MuiButton-root,
  .MuiButtonBase-root,
  .MuiInputBase-input,
  .MuiFormLabel-root {
    font-size: 12px !important;
  }
  .MuiIconButton-label {
    font-size: 1.5rem !important;
  }
  .slots-container .slot-button {
    font-size: 10px !important;
  }
  .correct-check-container {
    small {
      display: block;
    }
  }
}
code,
small,
p {
  font-family: 'Nunito', sans-serif !important;
}
.bold {
  font-weight: bold;
}
.wrapper {
  // background: #fbfbfb;
  outline: none;
}
.body-wrapper {
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
header {
  height: $header-height;
  left: 0 !important;
  z-index: 9999 !important;
}
main {
  padding: 10px;
  display: block;
}
.main-margin-btm {
  margin-bottom: 5.3rem;
}
.overflowAuto {
  overflow: auto;
}
.z11000,
.MuiDialog-root,
.MuiPopover-root,
.MuiSnackbar-root {
  z-index: 11000 !important;
}
.MuiAutocomplete-popper {
  z-index: 12000 !important;
}
.side-menu > .MuiMenu-paper {
  top: 41px !important;
}
.MuiPickersDay-dayDisabled {
  color: rgb(110, 107, 107) !important;
  pointer-events: none;
}
.MuiPickersCalendarHeader-dayLabel {
  color: #313275 !important;
}
footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  padding: 10px;
  padding-top: 0px;
  z-index: 3;
  background-color: #fbfbfb;
}
footer.fixedbtm {
  bottom: 15px;
  width: 95vw !important;
}
.stepper-footer {
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0;
  width: inherit;
  .MuiButton-root {
    font-size: 1rem;
    height: 42px;
  }
  .MuiButtonBase-root {
    border-radius: 0 !important;
    padding: 6px 0px;
    &:first-child {
      border-right: 1px solid #4e5c73;
    }
    &:last-child {
      border-left: 1px solid #4e5c73;
    }
  }
  .MuiButton-contained.Mui-disabled {
    background-color: #394e73 !important;
    color: #7f96bb !important;
  }
}
.iconPrimary {
  color: $PrimaryColor;
}
.iconSecondary {
  color: $SecondaryColor;
}
.iconFadeOut {
  color: $textFadeOut !important;
}
.iconWhite {
  color: $textWhite;
}
.f-weight-700 {
  font-weight: 700 !important;
}
.textPrimary {
  color: $textColor;
}
.borderB {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.borderBox {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.disclaimer {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px;
  border-radius: 4px;
}
.color-gray {
  color: $color-aa-gray;
}
.cpx-required {
  color: $color-aa-red !important;
}
.primaryBg {
  background: $PrimaryColor;
}
.justify {
  text-align: justify;
}
.justifyCenter {
  justify-content: center;
}
.w-120 {
  width: 120px;
}
.w20 {
  width: 20vw;
}
.width85 {
  width: 85vw !important;
}
.w80 {
  width: 80%;
}
.w40 {
  width: 40%;
}
.fullWidth {
  width: 100%;
}
.w100 {
  width: 100% !important;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.shadowNone {
  box-shadow: none !important;
}
.required-label {
  text-align: left;
  font-style: italic;
  // display: flex;
  font-size: 0.2rem;
  .required_field {
    color: $color-aa-red;
    font-size: 0.8rem;
  }
}
.labeled {
  font-size: 0.7rem;
  color: $color-aa-gray;
}
.text-center {
  text-align: center;
}
.btn-primary {
  height: 42px;
  font-size: 1rem;
}
.btn-border {
  border: $btnBorder;
}
.btn-top-border {
  border-top: $btnBorder;
}
.btn-bottom-border {
  border-bottom: $btnBorder;
}
.btn-bottom-border-none {
  border-bottom: none !important;
}
.btn-top-border-none {
  border-top: none !important;
}
.btn-left-border {
  border-left: $btnBorder;
}
.btn-right-border {
  border-right: $btnBorder;
}
.btn-left-border-none {
  border-left: none !important;
}
.btn-right-border-none {
  border-right: none !important;
}
.btn-bottom-border-none {
  border-bottom: none !important;
}
.inner-card {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.page-title {
  h4 {
    margin: 0;
    color: $PrimaryColor;
  }
}
.visibility-hidden {
  visibility: hidden;
}
/*=============
 Theme Styles
 ============== */
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiTypography-root,
.MuiBottomNavigationAction-label {
  font-family: 'Nunito', sans-serif !important;
}
.MuiCardContent-root {
  padding: 10px !important;
}
.MuiInputLabel-asterisk {
  color: $textError;
}
.MuiFormHelperText-root.Mui-error {
  color: $textError !important;
}
.MuiStepLabel-label.MuiStepLabel-completed {
  color: $SecondaryColor !important;
}
.MuiDialog-paper {
  margin: 15px !important;
}
.MuiDialog-paperFullWidth,
.MuiDialog-paperScrollPaper {
  width: calc(100% - 10px);
}
.MuiDialogTitle-root {
  padding: 15px ​15px 5px 15px !important;
  // border-bottom: 1px solid #ddd;
  .MuiTypography-root {
    font-size: 16px;
    font-weight: bold;
    color: $SecondaryColor;
  }
}
.MuiDialogActions-root {
  .MuiButtonBase-root {
    font-weight: 600;
  }
}
.MuiDialogContent-root {
  padding: 16px !important;
}
.Mui-required {
  color: $color-aa-red !important;
}
.MuiAccordion-root {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px !important;
  box-shadow: none !important;
  margin-bottom: 5px;
  &::before {
    background-color: transparent !important;
  }
  .MuiAccordionSummary-root {
    min-height: 30px !important;
    margin: 0 !important;
    .MuiAccordionSummary-content,
    .MuiIconButton-root {
      padding: 5px 0 !important;
      margin: 0 !important;
    }
  }
}
.MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
  margin-bottom: 5px !important;
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 30px;
  }
  .MuiIconButton-root {
    padding: 5px;
  }
}
.MuiAccordionDetails-root {
  padding: 0px 8px 8px 8px;
}
.MuiButton-containedSecondary {
  color: $textWhite !important;
}
.MuiCardContent-root:last-child {
  padding-bottom: 10px !important;
}
.MuiTab-root {
  text-transform: none !important;
  font-size: 0.775rem;
  padding: 9px !important;
}
.MuiTabScrollButton-root {
  width: 15px !important;
}
.tabWrapper {
  height: calc(100vh - 170px);
  overflow: auto;
  padding-right: 5px;
}
.iFrameWrapper {
  height: calc(100vh - 270px);
  overflow: hidden;
  padding: 0;
}
.horizontal-tabs {
  .MuiTab-root {
    text-transform: none;
    flex: 1;
    max-width: none !important;
  }
}
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 130px !important;
  }
}
.block-list {
  h4 {
    margin-bottom: 5px !important;
    font-weight: 600;
  }
  ul {
    list-style: none;
    padding: 0;
    margin-top: 0px;
    li {
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding: 5px !important;
      display: flex;
      margin-bottom: 10px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.summary-list {
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
    label {
      // min-width: 80px;
      margin-right: 5px;
      color: $color-aa-gray;
    }
  }
}
/*=============
 Margins
 ============== */
.mar0 {
  margin: 0 !important;
}
.marT0 {
  margin-top: 0px !important;
}
.marT5 {
  margin-top: 5px !important;
}
.marB8 {
  margin-bottom: 8px !important;
}
.marB0 {
  margin-bottom: 0px !important;
}
.marB5 {
  margin-bottom: 5px !important;
}
.marB10 {
  margin-bottom: 10px !important;
}
.marB15 {
  margin-bottom: 15px !important;
}
.marT15 {
  margin-top: 15px !important;
}
.marB20 {
  margin-bottom: 20px !important;
}
.marL10 {
  margin-left: 10px !important;
}
.marL15 {
  margin-left: 15px !important;
}
.marR10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 80px;
}
.img-header {
  margin-right: 10px !important;
  object-fit: cover;
  padding: 3px;
  width: 32px;
  height: 32px;
}
/*=============
 Paddings
 ============== */
.pad0 {
  padding: 0 !important;
}
.pad8 {
  padding: 8px !important;
}
.padB0 {
  padding-bottom: 0 !important;
}
.pad10 {
  padding: 10px;
}
.pad20 {
  padding: 20px;
}
.padR10 {
  padding-right: 10px;
}
.padT10 {
  padding-top: 10px;
}
.padL10 {
  padding-left: 10px;
}
.loginSpan {
  margin: 24px 24px 0px 0px;
  padding-left: 24px;
  display: inline-block;
}
.form-label {
  margin-bottom: 5px;
  display: inline-block;
}
.m-0 {
  margin: 0;
}
.marginT10 {
  margin-top: 10px;
}
.padL15 {
  padding-left: 15px !important;
}
.padL15only {
  padding: 0px 0px 0px 15px !important;
}
.cancel-padding {
  padding: 17px 22px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif !important;
}
p {
  font-size: 14px;
}
small {
  font-size: 12px;
}
.bgWhite {
  background-color: $colorWhite !important;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: none;
}

.customBtn,
.photo-upload {
  padding: 0 !important;
  background: rgba(113, 170, 66, 0.18);
  border: 1px solid $SecondaryColor;
  border-radius: 4px;
  .MuiIconButton-root {
    width: 45px;
  }
  .MuiButton-root {
    padding-right: 45px !important;
    height: 40px;
    font-size: 0.85rem;
  }
  .MuiButtonBase-root {
    border-radius: 0 !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    color: $SecondaryColor !important;
    font-weight: 600;
  }
}
.photo-upload .MuiButtonBase-root {
  font-weight: 600 !important;
}
.photo-upload {
  margin-bottom: 10px;
}
.customBtn-padding6 {
  padding: 4px !important;
  background: rgba(114, 171, 67, 0.18);
  border: 1px solid $SecondaryColor;
  .MuiButtonBase-root {
    border-radius: 0 !important;
    padding: 2px 0 2px 20px !important;
    color: $SecondaryColor !important;
    font-weight: 600;
  }
}
.bord-rad-0 {
  border-radius: 0 !important;
}
.box-secondary {
  border: 1px solid $SecondaryColor;
  padding: 10px;
  margin-bottom: 10px;
}
/*Inbox Styles */
.inbox-footer {
  border-top: 2px solid $PrimaryColor;
  height: 5vh;
}
.msg-row {
  border-bottom: 1px solid #ddd;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.msg-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.msg-row .msg-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.msg-row a {
  text-decoration: none;
  color: inherit;
}
.msg-middle h4,
.msg-middle h5,
.msg-middle p {
  margin: 0;
}
.msg-middle p,
.msg-row small {
  color: #635f5f;
}

.floatBtn {
  position: fixed;
  bottom: 10vh;
  width: 95%;
}
.align-left-btn {
  .MuiButton-label {
    justify-content: start !important;
  }
}
.avathar-icon {
  width: 57px !important;
  height: 57px !important;
  margin-right: 8px;
}
.upload {
  display: flex;
  align-items: center;
  min-height: 70px;
  border-bottom: 1px solid #ddd;
}
.upload-icon {
  border: 1px solid #ddd;
  padding: 5px;
  margin-right: 3%;
  color: $textFadeOut;
}
.captcha {
  display: flex;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

/* echcekIn Styles */
.cardBlockX {
  border: 1px solid $SecondaryColor !important;
}
.cardBorderBottom {
  .MuiCardContent-root {
    border-bottom: 1px solid $SecondaryColor !important;
  }
}
.bgNone {
  background: none !important;
}
.cardBlock-2 {
  margin: 8px 0px;
  border: 1px solid transparent !important;
  border-radius: 5px;
  .card-header {
    padding: 5px;
    align-items: center;
    color: $textWhite;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: $SecondaryColor;
    .MuiTypography-h5 {
      font-size: 14px !important;
      font-weight: 500 !important;
      margin-bottom: 0 !important;
    }
  }
  .MuiCardContent-root {
    background-color: $textWhite;
    padding: 5px !important;
    border-left: 1px solid $borderColorG;
    border-right: 1px solid $borderColorG;
    &.card-content-2 {
      border-top: 1px solid $borderColorG;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &.card-content-3 {
      border-bottom: 1px solid $borderColorG;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  .card-footerbg-primary {
    padding: 0 !important;
    background-color: #e2edda !important;
    border: 1px solid $borderColorG;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    .MuiIconButton-root {
      padding: 2px 10px !important;
    }
    .MuiButtonBase-root {
      border-radius: 0 !important;
      padding: 6px 0px;
      color: $SecondaryColor !important;
      font-weight: 600;
    }
  }
}
.cardBlock {
  margin: 8px 0px;
  border: 1px solid $borderColorG !important;
  &.MuiPaper-root {
    background-color: $SecondaryColor;
  }
  .card-header {
    padding: 5px;
    align-items: center;
    color: $textWhite;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .MuiTypography-h5 {
      font-size: 14px !important;
      font-weight: 500 !important;
      margin-bottom: 0 !important;
    }
  }
  .MuiCardContent-root {
    background-color: $textWhite;
    padding: 5px;
  }
  .card-footerbg-primary {
    padding: 0 !important;
    background-color: #e2edda !important;
    border-top: 1px solid $borderColorG;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    .MuiIconButton-root {
      padding: 2px 10px !important;
    }
    .MuiButtonBase-root {
      border-radius: 0 !important;
      padding: 6px 0px;
      color: $SecondaryColor !important;
      font-weight: 600;
    }
  }
}
.bt-0 {
  border-top: none !important;
}
.insuranceUpload-customBtn,
.card-customBtn {
  padding: 0 !important;
  border-top: 1px solid $borderColorG;
  .MuiIconButton-root {
    width: 45px;
  }
  .MuiButton-root {
    padding-right: 45px !important;
  }
  .MuiButtonBase-root {
    border-radius: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    color: $SecondaryColor !important;
    font-weight: 600;
  }
}

.checkbox-font {
  font-size: 0.8rem;
}
/*Appointments  */
.visit_date {
  padding: 0px 8px;
  // width: 20%;
  text-align: center;
  h3,
  h2,
  h1 {
    margin: 0 !important;
  }
  p {
    margin: 0 !important;
  }
  h2 {
    color: $SecondaryColor;
  }
}
.visit_info {
  h4 {
    margin: 0 !important;
  }
  p {
    margin: 0 !important;
  }
}
.margin-left-10 {
  margin-left: 10px;
}
.nowrap {
  white-space: nowrap !important ;
}
.padding-top {
  padding-top: 0.4rem;
}
.visit_title {
  color: $SecondaryColor !important;
}
.HospitalIcon {
  color: $SecondaryColor;
}

.ApptTypeIcon {
  color: $SecondaryColor;
}
.visitInfoTime {
  width: 80%;
  h5 {
    color: $PrimaryColor;
    margin: 0 !important;
  }
  p {
    color: $textColor;
    margin: 0 !important;
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding: 5px;
}
@media screen and (min-width: 990px) {
  .wrapper,
  header,
  footer,
  .wizard-container .wizard-footer,
  .MuiBackdrop-root.backdrop-progress .MuiLinearProgress-root,
  .replyBox {
    margin: 0 auto !important;
  }
  footer {
    left: 0;
    right: 0;
  }
}
// @media screen and (min-width: 601px) and (max-width: 989px) {
//   .wrapper,
//   header,
//   footer,
//   .wizard-container .wizard-footer,
//   .MuiBackdrop-root.backdrop-progress .MuiLinearProgress-root {
//     width: 70vw !important;
//     margin: 0 auto !important;
//   }
// }

@media screen and (min-width: 600px) {
  body {
    font-size: 0.8rem;
  }
  p {
    font-size: 0.8rem;
  }
  .floatingBtn .MuiButtonBase-root {
    padding: 8px 0 8px 20px !important;
  }
  .cardBlockX {
    width: 100%;
  }
}

.custom-dialog-component-styles {
  @include dialogue-footer-actions;
}
.cardBlock-margin0 {
  margin: 0px auto;
  width: 100%;
  border: 1px solid $SecondaryColor !important;
  .card-header {
    padding: 5px;
    align-items: center;
    background: $SecondaryColor;
    color: $textWhite;
    .MuiTypography-h5 {
      font-size: 14px !important;
      font-weight: 500 !important;
      margin-bottom: 0 !important;
    }
  }
  .center-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    .img {
      height: 120px;
      width: 130px;
      margin: 0.5rem auto;
    }
  }
  .pay-billing-wrapper {
    height: calc(100vh - 13vh) !important;
  }

  .center-img-noBorder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    .img {
      height: 120px;
      width: 130px;
      margin: 0.5rem auto;
    }
  }
  .card-footerbg-primary {
    padding: 0 !important;

    background: rgba(114, 171, 67, 0.18);
    border-top: 1px solid $SecondaryColor;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    .MuiIconButton-root {
      padding: 0 0 0 20px !important;
    }
    .MuiButtonBase-root {
      border-radius: 0 !important;
      padding: 6px 0px;
      color: $SecondaryColor !important;
      font-weight: 600;
    }
  }
}
.MuiCardActions-spacing > :not(:first-child) {
  margin-left: 0px !important;
}

.button-with-icon {
  background: rgba(113, 170, 66, 0.18) !important;
  border: 1px solid $SecondaryColor !important;
  border-radius: 4px !important;
  color: $SecondaryColor !important;
  font-weight: 600 !important;
  .MuiButton-label {
    display: flex;
  }
  .MuiButton-startIcon {
    flex: 0;
    position: relative;
    left: 10px;
  }
  .button-text {
    flex: 1;
  }
}

.card-action-with-button-icon {
  padding: 0px !important;
  height: 30px !important;

  .MuiButtonBase-root {
    border-radius: 0px !important;
    border: none !important;
    border-top: 1px solid $borderColorG !important;
    padding: 0px;
    height: 100%;
  }

  .MuiButton-startIcon {
    .MuiIconButton-label {
      color: $SecondaryColor;
    }
    left: 20px;
  }
}
/*QR Code Modal in eCheck-in*/
.qrcode {
  text-align: center;
  margin-bottom: 25px;
}
.float-right {
  float: right;
  top: 0;
}
.modal-content {
  p {
    color: $color-aa-gray;
    margin: 3px 0;
  }
  span {
    color: #000;
  }
}
.appointmentDetails {
  list-style-type: none;
  padding: 0;
  margin-bottom: 25px;
  margin-top: 0px;
  label {
    width: auto;
    min-width: 75px;
    color: $color-aa-gray;
    display: inline-block;
  }
  li {
    margin-bottom: 5px;
  }
}

.msgBox {
  display: block;
  height: 71vh;
  width: 100%;
  overflow: auto;
  & div:last-child {
    margin-bottom: 50px;
  }
  .receievedMsg,
  .sentMsg {
    width: 85%;
    margin: 5px;
    p {
      border: 1px solid #ddd;
      padding: 5px;
      margin: 0;
      border-radius: 5px;
      margin-top: 3px;
    }
    span {
      color: $color-aa-gray;
      font-size: 0.8rem;
    }
  }
  .sentMsg {
    float: right;
    width: 85%;
    p {
      border-radius: 20px;
      border-bottom-right-radius: 0px !important;
      background-color: $PrimaryColor;
      color: $colorWhite;
      padding: 10px;
    }
    span {
      float: right;
    }
    div {
      float: right;
    }
  }
  .receievedMsg {
    float: left;
    p {
      border-radius: 20px;
      border-top-left-radius: 0px !important;
      background-color: $colorWhite;
      padding: 10px;
    }
  }
}
.bgOffWhite {
  background-color: #f5f5f5;
}
.replyBox {
  position: fixed;
  bottom: 49px;
  background: #fbfbfb;
  z-index: 5;
  width: auto;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0px 10px;
}
.bordBNone {
  border-bottom: none !important;
}
.questionnaireLabel {
  width: auto;
  min-width: 75px;
  color: $color-aa-gray;
  display: inline-block;
}
.btn-primary-bg {
  padding: 0 !important;
  border: 1px solid $PrimaryColor;
  border-radius: 4px;
  .MuiIconButton-root {
    padding: 5px 0 5px 10px !important;
    .MuiIconButton-label {
      font-size: 1.5rem;
    }
  }
  .MuiButtonBase-root {
    border-radius: 0 !important;
    padding: 5px 0px;
    color: $PrimaryColor !important;
    font-weight: 600;
    font-size: 1rem;
    height: 40px;
  }
}
.floatingBtn {
  padding: 0 !important;
  position: fixed;
  bottom: 10px;
  width: 80%;
  left: 10%;
  right: 10%;
  background: $PrimaryColor;
  border: 1px solid $PrimaryColor;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  .MuiIconButton-root {
    padding: 5px 0 5px 10px !important;
    .MuiIconButton-label {
      font-size: 1.5rem;
    }
  }
  .MuiButtonBase-root {
    border-radius: 0 !important;
    padding: 5px 0px;
    color: $textWhite !important;
    font-weight: 600;
    font-size: 1rem;
    height: 40px;
  }
}
@media screen and (min-width: 600px) {
  .floatingBtn {
    width: 52%;
    left: 24%;
    right: 24%;
  }
}

@media screen and (min-width: 1366px) {
  .floatingBtn {
    width: 25%;
    left: 37.5%;
    right: 37.5%;
  }
}
@media screen and (min-width: 767px) {
  .msgBox {
    height: 80vh !important;
  }
}
.display-list {
  .row {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  label {
    color: $color-aa-gray;
  }
  .content-name {
    font-weight: bold;
    color: $SecondaryColor;
  }
}
.lab-results-content,
.pn-content {
  margin: 10px 0;
  .lr-header {
    padding: 10px 0;
    // border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    label {
      color: $color-aa-gray;
    }
  }
  .lr-body {
    padding: 10px 0;
  }
}
.data-table {
  width: 98%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  td,
  th {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    text-align: left;
    border-top: none;
  }
  .header-row td {
    background-color: $SecondaryColor;
    color: $colorWhite;
  }
  tr:nth-child(even) {
    background: rgba(113, 170, 66, 0.1);
  }
  tbody {
    th {
      background: $colorWhite;
      color: #000;
    }
  }
}
.table-body:not(:last-of-type) {
  border-bottom: 2px solid #000;
}
.abnormal-result {
  color: $color-aa-red;
}
.summary {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 8px;
  li {
    list-style-type: none;
    padding: 2px;
  }
  label {
    color: $color-aa-gray;
  }
}
.med-info {
  label {
    color: $color-aa-gray;
  }
}
.pointer:hover {
  cursor: pointer;
}
.aLink {
  a {
    word-break: break-word;
  }
}
.text-justify {
  text-align: justify;
}
// .sl-addInfo {
//   width: 100%;
//   background: #fbfbfb;
//   position: fixed;
//   z-index: 999;
// }
.mt-70 {
  margin-top: 70px;
}
.marginT11 {
  margin-top: 11px;
}
.MuiButton-contained:hover.Mui-disabled {
  background-color: #ddd !important;
}
.kiosk-mode {
  width: 100% !important;
  font-size: 1.3rem;
  header {
    width: 100% !important;
  }
}
.f-15,
.pointer {
  font-size: 1.5rem;
}
.minWidth35 {
  min-width: 35px !important;
}
.lineBox {
  .leftArea {
    width: 80%;
  }
  .MuiIconButton-root {
    background-color: #fbfbfb;
  }
}
.f-16 {
  font-size: 16px;
}
.doc-file {
  width: 90%;
  height: 90%;
  padding: 4%;
  border: 1px #000 solid;
}
.input-file {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
}
.practicelocationlabel {
  font-size: 14px;
  font-weight: bold;
}
.linkedAccount {
  color: $linkedAccountColor;
}
